import React, { useState, useEffect } from "react";
import { Table, Select, Tag,DatePicker } from "antd";
import { instance } from "utils/axios";
import moment from "moment";
const { Option } = Select;

const NonCommercial = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [date, setDate] = useState(moment());
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
    // fetchCompetitors(); // Fetch competitor options
    //  fetchCategories();
    //  fetchSubCategories();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/main/noncommercials?date=${moment(date)
        .startOf("month")
        .format("YYYY-MM-DD")}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

 
 

const fetchCategories = () => {
  instance({
    method: "get",
    url: `/categories`, // Replace with the correct API endpoint
  })
    .then((res) => {
      setCategories(res.data);
    })
    .catch((err) => {
      console.error("Error fetching categories:", err);
    });
};

const fetchSubCategories = () => {
  instance({
    method: "get",
    url: `/subcategories`, // Replace with the correct API endpoint
  })
    .then((res) => {
      setSubCategories(res.data);
    })
    .catch((err) => {
      console.error("Error fetching subcategories:", err);
    });
};

const handleSetCategory = (value, row) => {
  // Update the categoryId in the row
  console.log("Updated Category ID:", value, "for row:", row);
  instance({
    method: "post",
    url: `/main/update`, // Replace with the correct API endpoint
    data: {
      ...row,
      categoryId: value,
      subCategoryId: null, // Reset subCategoryId if category changes
    },
  })
    .then((res) => {
      console.log("Updated successfully:", res);
      fetchData(); // Optionally re-fetch data to reflect the changes
    })
    .catch((err) => {
      console.error("Error updating categoryId:", err);
    });
};

const handleSetSubCategory = (value, row) => {
  // Update the subCategoryId in the row
  console.log("Updated SubCategory ID:", value, "for row:", row);
  instance({
    method: "post",
    url: `/main/update`, // Replace with the correct API endpoint
    data: {
      ...row,
      subCategoryId: value,
    },
  })
    .then((res) => {
      console.log("Updated successfully:", res);
      fetchData(); // Optionally re-fetch data to reflect the changes
    })
    .catch((err) => {
      console.error("Error updating subCategoryId:", err);
    });
};

const columns = [
  { title: "Жил", dataIndex: "cYear", key: "cYear" },
  { title: "Улирал", dataIndex: "quarter", key: "quarter" },
  // {
  //   title: "Байгууллага",
  //   dataIndex: "competitorId",
  //   key: "competitorId",
  //   render: (text, row) => (
  //     <span>
  //       {row.competitor.regno} {row.competitor.name}
  //     </span>
  //   ),
  // },
  {
    title: "Төрөл",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Регистер",
    dataIndex: "regno",
    key: "regno",
  },
  {
    title: "Нэр",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Горим",
    dataIndex: "mode",
    key: "mode",
  },
  {
    title: "Огноо",
    dataIndex: "cDate",
    key: "cDate",
  },
  {
    title: "Бараа код",
    dataIndex: "itemCode",
    key: "itemCode",
  },
  {
    title: "Бараа нэр",
    dataIndex: "itemName",
    key: "itemName",
  },
  {
    title: "Зориулалт",
    dataIndex: "dedication",
    key: "dedication",
  },
  {
    title: "Үйлд.он",
    dataIndex: "productionYear",
    key: "productionYear",
  },
  {
    title: "Марк",
    dataIndex: "mark",
    key: "mark",
  },
  {
    title: "Нэгж",
    dataIndex: "unit",
    key: "unit",
  },
  {
    title: "Тоо",
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "Үнэ.$",
    dataIndex: "priceDollar",
    key: "priceDollar",
  },
  {
    title: "Гааль.₮",
    dataIndex: "custom",
    key: "custom",
  },

  {
    title: "Гарал.улс",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "Илгээгч улс",
    dataIndex: "sentCountry",
    key: "sentCountry",
  },
  {
    title: "Үнэ.₮",
    dataIndex: "priceTugrug",
    key: "priceTugrug",
  },
  {
    title: "Илгээгч",
    dataIndex: "sender",
    key: "sender",
  },
];

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className="col-span-6 md:flex items-center gap-2">
        <DatePicker picker="month" value={date} onChange={(e) => setDate(e)} />
      </div>
      <div className="border bg-white border-gray-200 rounded-lg shadow">
        <Table
          dataSource={data}
          columns={columns}
          loading={loading}
          pagination={true}
          rowKey={(record, index) => index}
        />
      </div>
    </div>
  );
};

export default NonCommercial;
