import React, { useEffect, useState } from "react";
import { Button, Form as AntForm, Tag, Modal, Upload } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";

function Training() {
  const [trainings, setTrainings] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const [form] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/trainings`, // Update API endpoint
    })
      .then((res) => {
        setTrainings(res.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Сургалтын гарчиг",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Эхлэх хугацаа",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "Дуусах хугацаа",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Байршил",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Төлбөр",
      dataIndex: "fee",
      key: "fee",
      render: (fee) => `${fee}₮`,
    },
    {
      title: "Төрөл",
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <Tag color={type === "Online" ? "blue" : "green"}>
          {type === "Online" ? "Онлайн" : "Танхим"}
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleEdit(row)}>
          <div className="flex items-center gap-2">
            <EditOutlined /> Засах
          </div>
        </Button>
      ),
    },
  ];

  const handleAdd = () => {
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };

  const fields = [
    {
      label: "Сургалтын гарчиг",
      name: "title",
      rules: [{ required: true, message: "Сургалтын гарчиг оруулна уу!" }],
      className: "col-span-12 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Тайлбар",
      name: "description",
      rules: [{ required: true, message: "Тайлбар оруулна уу!" }],
      type: "textarea",
      className: "col-span-12 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Эхлэх хугацаа",
      name: "startDate",
      rules: [{ required: true, message: "Эхлэх хугацааг сонгоно уу!" }],
      className: "col-span-6 mb-0",
      type: "date",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Дуусах хугацаа",
      name: "endDate",
      rules: [{ required: true, message: "Дуусах хугацааг сонгоно уу!" }],
      className: "col-span-6 mb-0",
      type: "date",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Байршил",
      name: "location",
      rules: [{ required: true, message: "Байршил оруулна уу!" }],
      className: "col-span-12 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Төлбөр",
      name: "fee",
      rules: [{ required: true, message: "Төлбөр оруулна уу!" }],
      className: "col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        type: "number",
      },
    },
    {
      label: "Төрөл",
      name: "type",
      rules: [{ required: true, message: "Сургалтын төрлийг сонгоно уу!" }],
      className: "col-span-6 mb-0",
      type: "select",
      inputProps: {
        className: "w-full",
        options: [
          { value: "Online", label: "Онлайн" },
          { value: "Offline", label: "Танхим" },
        ],
      },
    },
  ];

  const handleSubmit = (values) => {
    setLoading(true);
    if (editData) {
      instance({
        method: "put",
        url: "/training", // Update endpoint
        data: { ...values, id: editData.id },
      })
        .then(() => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/training", // Update endpoint
        data: values,
      })
        .then(() => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    }
  };

  const handleCloseModal = () => {
    setEditData(null);
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={trainings}
          columns={columns}
          setCurrentPage={setCurrentPage}
          loading={loading}
          pagination={true}
          title={"Сургалт"}
          handleAdd={handleAdd}
        />
      </div>
      <Modal
        title={editData ? "Сургалт засах" : "Шинэ сургалт нэмэх"}
        open={open}
        destroyOnClose
        width={800}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}

export default Training;
