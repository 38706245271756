import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form as AntForm, Button, Upload, DatePicker } from "antd";
import { instance } from "utils/axios";
import { Form } from "components";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";

function NewsForm() {
    const editorRef = useRef(null); 
  const { id } = useParams();
  const [form] = AntForm.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const isEdit = !!id;

  useEffect(() => {
    if (isEdit) {
      instance({
        method: "get",
        url: `/news/${id}`,
      })
        .then((res) => {
          setEditData(res.data);
          form.setFieldsValue({
            ...res.data,
            postDate: res.data.postDate ? moment(res.data.postDate) : null,
          });
        })
        .catch((err) => console.error(err));
    }
  }, [id, form, isEdit]);

  const handleSubmit = (values) => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key === "image" && values.image?.file) {
        formData.append(key, values.image.file);
      } else if (key === "postDate") {
        formData.append(
          key,
          values.postDate ? values.postDate.format("YYYY-MM-DD HH:mm") : ""
        );
      } else {
        formData.append(key, values[key] || "");
      }
    });

    // const apiCall = isEdit
    //   ? instance.put("/news", { ...values, id })
    //   : instance.post("/news", values);

    // apiCall
    //   .then(() => {
    //     navigate("/news");
    //   })
    //   .catch((err) => console.error(err))
    //   .finally(() => setLoading(false));
    //   setLoading(true);
    //   const formData = new FormData();
    //   Object.keys(values).forEach((key) => {
    //     if (key === "image" && values.image?.file) {
    //       formData.append(key, values.image.file);
    //     } else if (key === "postDate") {
    //       formData.append(
    //         key,
    //         values.postDate ? values.postDate.format("YYYY-MM-DD HH:mm") : ""
    //       );
    //     } else {
    //       formData.append(key, values[key] || "");
    //     }
    //   });

      if (editData) {
        formData.append("id", editData.id);
        instance({
          method: "put",
          url: "/news", // API to update news
          data: formData,
        })
          .then(() => {
            navigate("/news");
          })
          .catch((err) => {})
          .finally(() => setLoading(false));
      } else {
        instance({
          method: "post",
          url: "/news", // API to create new news
          data: formData,
        })
          .then(() => {
             navigate("/news");
          })
          .catch((err) => {})
          .finally(() => setLoading(false));
      }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-2xl font-bold mb-6 text-center">
          {isEdit ? "Мэдээ засах" : "Шинэ мэдээ нэмэх"}
        </h1>
        <Form
          onInit={(evt, editor) => (editorRef.current = editor)}
          form={form}
          fields={[
            {
              label: "Гарчиг",
              name: "title",
              rules: [{ required: true, message: "Гарчиг оруулна уу!" }],
              className: "col-span-12 mb-0",
              inputProps: {
                placeholder: "Гарчиг",
              },
            },
            {
              label: "Нийтлэл",
              name: "content",
              rules: [{ required: true, message: "Нийтлэл оруулна уу!" }],
              type: "editor",
              className: "col-span-12 mb-0",
              inputProps: {
                className: "w-full",
              },
            },
            {
              label: "Нийтлэх огноо",
              name: "postDate",
              className: "col-span-12 mb-0",
              render: () => (
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  className="w-full"
                />
              ),
            },
            {
              label: "Зураг",
              name: "image",
              type: "file",
              className: "col-span-12 mb-0",
              render: () => (
                <Upload
                  name="image"
                  listType="picture"
                  maxCount={1}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>Зураг сонгох</Button>
                </Upload>
              ),
            },
          ]}
          layout="vertical"
          onFinish={(values) => {
            const content = editorRef.current?.getContent(); // Editor-оос шууд агуулгыг авах
            const processedValues = {
              ...values,
              content,
            };
            handleSubmit(processedValues);
          }}
          editData={editData}
          className="gap-4"
        >
          <AntForm.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="w-full"
            >
              {isEdit ? "Хадгалах" : "Нэмэх"}
            </Button>
          </AntForm.Item>
        </Form>
      </div>
    </div>
  );
}

export default NewsForm;
