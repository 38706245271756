import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { MainTable } from "components";
import { instance } from "utils/axios";

function Service() {
  const [data, setServiceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/services`, // API to fetch all services
    })
      .then((res) => {
        setServiceData(res.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Агуулга",
      dataIndex: "content",
      key: "content",
      width: 300,
      ellipsis: true,
      render: (text) => <span title={text}>{text?"..." : "empty"}</span>,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, row) => (
        <Button
          size="small"
          onClick={() => navigate(`/service/${row.id}`)}
        >
          Засах
        </Button>
      ),
    },
  ];

  const handleAdd = () => {
    navigate(`/service/new`);
  };

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={data}
          columns={columns}
          loading={loading}
          title={"Үйлчилгээ"}
          handleAdd={handleAdd}
        />
      </div>
    </div>
  );
}

export default Service;
