import {
  Dashboard,
  Employee,
  Doctor,
  Partner,
  News,
  NonCommercial,
  CommercialFinished,
  JobApplicants,
  Training,
  Service,
  ServiceForm,
  NewsForm,
} from "modules/admin";
import JobPosition from "modules/admin/job_position";

export default [
  {
    name: "Хянах самбар",
    path: "/",
    element: <Dashboard />,
    role: ["Админ", "Удирдлага", "Менежер"],
  },
  {
    name: "job postion",
    path: "/job/postion",
    element: <JobPosition />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "job postion",
    path: "/job/applicants",
    element: <JobApplicants />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "training",
    path: "/training",
    element: <Training />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "doctor",
    path: "/doctor",
    element: <Doctor />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "news",
    path: "/news",
    element: <News />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "Main",
    path: "/finished",
    element: <CommercialFinished />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "Main",
    path: "/noncommercial",
    element: <NonCommercial />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "Өрсөлдөгч",
    path: "/partner",
    element: <Partner />,
    role: ["CEO", "Админ", "Менежер"],
  },

  {
    name: "Ажилчид",
    path: "/employee",
    role: ["Админ", "Удирдлага", "Менежер"],
    element: <Employee />,
  },
  {
    name: "Ажилчид",
    path: "/service",
    role: ["Админ", "Удирдлага", "Менежер"],
    element: <Service />,
  },
  {
    name: "Үйлчилгээ",
    path: "/service/:id",
    role: ["Админ", "Удирдлага", "Менежер"],
    element: <ServiceForm />,
  },
    {
    name: "Үйлчилгээ",
    path: "news/add/",
    role: ["Админ", "Удирдлага", "Менежер"],
    element: <NewsForm />,
  },
    {
    name: "Үйлчилгээ",
    path: "/news/edit/:id",
    role: ["Админ", "Удирдлага", "Менежер"],
    element: <NewsForm />,
  },
  // Тохирг
];
