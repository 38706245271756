import React, { useEffect, useState } from "react";
import { Button, Form as AntForm, Tag, Modal, Upload } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";

function JobPosition() {
  const [positions, setPositions] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const [form] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/job/positions`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Гарчиг",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Хэлбэр",
      dataIndex: "workMode",
      key: "workMode",
    },
    {
      title: "Байрлал",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Шаардлага",
      dataIndex: "requirements",
      key: "requirements",
    },
    {
      title: "Цалин",
      dataIndex: "salary",
      key: "salary",
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span>
          {status === "open" ? (
            <Tag color="green">Нээлттэй</Tag>
          ) : (
            <Tag color="red">Хаагдсан</Tag>
          )}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleEdit(row)}>
          <div className="flex items-center gap-2">
            <EditOutlined /> Засах
          </div>
        </Button>
      ),
    },
  ];

  const handleAdd = () => {
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };
const fields = [
  {
    label: "Гарчиг",
    name: "title",
    rules: [{ required: true, message: "Гарчиг оруулна уу!" }],
    className: "col-span-12 mb-0",
    inputProps: {
      className: "w-full",
    },
  },
  {
    label: "Хэлбэр",
    name: "workMode",
    rules: [{ required: true, message: "Хэлбэрийг сонгоно уу!" }],
    className: "col-span-12 mb-0",
    // type: "select",
    inputProps: {
      className: "w-full",
      // options: [
      //   { value: "Бүтэн цаг", label: "Бүтэн цаг" },
      //   { value: "Хагас цаг", label: "Хагас цаг" },
      //   { value: "Шөнийн ээлж", label: "Шөнийн ээлж" },
      //   { value: "Яаралтай", label: "Яаралтай" },
      // ],
    },
  },
  {
    label: "Шаардлага",
    name: "requirements",
    className: "col-span-12 mb-0",
    type: "textarea",
    inputProps: {
      className: "w-full",
    },
  },
  {
    label: "Тайлбар",
    name: "description",
    // rules: [{ required: true, message: "Тайлбар оруулна уу!" }],
    type: "textarea",
    className: "col-span-12 mb-0",
    inputProps: {
      className: "w-full",
    },
  },
  {
    label: "Байрлал",
    name: "location",
    className: "col-span-12 mb-0",
    type: "textarea",
    inputProps: {
      className: "w-full",
    },
  },

  // {
  //   label: "Цалин",
  //   name: "salary",
  //   className: "col-span-6 mb-0",
  //   inputProps: {
  //     className: "w-full",
  //   },
  // },
  {
    label: "Төлөв",
    name: "status",
    rules: [{ required: true, message: "Төлвийг сонгоно уу!" }],
    className: "col-span-6 mb-0",
    type: "select",
    inputProps: {
      className: "w-full",
      options: [
        { value: "open", label: "Нээлттэй" },
        { value: "closed", label: "Хаагдсан" },
      ],
    },
  },
];


  const handleSubmit = (values) => {
    setLoading(true);
    if (editData) {
      instance({
        method: "put",
        url: "/job/position",
        data: { ...values, id: editData.id },
      })
        .then(() => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/job/position",
        data: values,
      })
        .then(() => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    }
  };

  const handleCloseModal = () => {
    setEditData(null);
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={data}
          columns={columns}
          setCurrentPage={setCurrentPage}
          loading={loading}
          pagination={true}
          title={"Ажлын Байр"}
          handleAdd={handleAdd}
        />
      </div>
      <Modal
        title={editData ? "Ажлын байр засах" : "Шинэ ажлын байр"}
        open={open}
        destroyOnClose
        width={800}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}

export default JobPosition;
