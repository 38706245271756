import React, { useEffect, useState } from "react";
import { Button, Modal, Tag, Form as AntForm } from "antd";
import { EditOutlined, SearchOutlined, ClearOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import fields from "./fields";

function Partner() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [form] = AntForm.useForm();
  const [filterForm] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/partners`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Зураг",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (imageUrl) => (
        <img
          src={`${process.env.REACT_APP_CDN_URL}${imageUrl}`}
          alt="doctor"
          width={50}
          height={50}
        />
      ),
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <>
          <Button size="small" onClick={() => handleEdit(row)}>
            <div className="flex items-center gap-2">
              <EditOutlined /> Засах
            </div>
          </Button>
          <Button size="small" onClick={() => handleDelete(row.id)}>
            <div className="flex items-center gap-2">
              <EditOutlined /> Устгах
            </div>
          </Button>
        </>
      ),
    },
  ];

  const handleSubmit = (values) => {
    setLoading(true);
    if (editData) {
      instance({
        method: "put",
        url: "/partner",
        data: {
          ...values,
          id: editData.id,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/partner",
        data: {
          ...values,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };




  const handleAdd = () => {
    setIsEdit(true);
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };
   const handleDelete = (id) => {
      instance({
        method: "delete",
        url: "/partner/"+id,
      })
        .then((res) => {
          
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
   };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className="flex flex-col px-4 pb-4">   
      <MainTable
        dataTable={data}
        columns={columns}
        setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={true}
        title={"Харилцагч"}
        handleAdd={handleAdd}
      />
      <Modal
        title={editData ? "Харилцагч мэдээлэл засах" : "Шинэ Харилцагч"}
        open={open}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields()}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}

export default Partner;
