import { Form, Button, Upload } from "antd";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";
export default () => {
  return [
    {
      label: "Нэр",
      name: "name",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Зураг",
      name: "image",
      type: "file",
      // rules: [{ required: true, message: "Зураг оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
      render: () => (
        <Upload
          name="image"
          listType="picture"
          maxCount={1}
          beforeUpload={() => false} // Prevents auto-upload, use form submission instead
        >
          <Button icon={<UploadOutlined />}>Лого сонгох</Button>
        </Upload>
      ),
    },
  ];
};
