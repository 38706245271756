import React, { useState, useEffect } from "react";
import { Table, Button, Tag,DatePicker } from "antd";
import { instance } from "utils/axios";
import moment from "moment";
import * as XLSX from "xlsx";

const CommercialFinished = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [date, setDate] = useState(moment());
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
    // fetchCompetitors(); // Fetch competitor options
    //  fetchCategories();
    //  fetchSubCategories();
  }, []);
   useEffect(() => {
     fetchData(); // Fetch data when the component mounts
   }, [date]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/main/finished?date=${moment(date)
        .startOf("month")
        .format("YYYY-MM-DD")}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

 
const handleExport = () => {
  // Map the column titles to the first row
  const headers = columns.map((col) => col.title);

  // Extract data with names instead of IDs for specific columns
  const dataToExport = data.map((row) => {
    let newRow = {};

    columns.forEach((col) => {
      if (col.dataIndex === "competitorId") {
        newRow[col.title] = `${row.competitor.regno} ${row.competitor.name}`;
      } else if (col.dataIndex === "categoryId") {
        newRow[col.title] = row.category.name;
      } else if (col.dataIndex === "subCategoryId") {
        newRow[col.title] = row.sub_category.name;
      } else {
        newRow[col.title] = row[col.dataIndex];
      }
    });

    return newRow;
  });

  // Create the worksheet with headers and data
  const worksheet = XLSX.utils.json_to_sheet(dataToExport, { header: headers });

  // Create a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

  // Write the workbook to a file
  XLSX.writeFile(
    workbook,
    `commercial_${moment().format("YYYYMMDD_HHmmss")}.xlsx`
  );
};

const columns = [
  { title: "Жил", dataIndex: "cYear", key: "cYear" },
  { title: "Улирал", dataIndex: "quarter", key: "quarter" },
  {
    title: "Байгууллага",
    dataIndex: "competitorId",
    key: "competitorId",
    render: (text, row) => (
      <span>
        {row.competitor?.regno} {row.competitor?.name}
      </span>
    ),
  },
  {
    title: "Төрөл",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Ангилал",
    dataIndex: "categoryId",
    key: "categoryId",
    render: (text, row) => (
      <span>
        {row.category?.name} 
      </span>
    ),
  },
  {
    title: "Дэд ангилал",
    dataIndex: "subCategoryId",
    key: "subCategoryId",
    render: (text, row) => {
      console.log(row)
     return <span>{row.sub_category?.name}</span>;
    },
  },
  {
    title: "Горим",
    dataIndex: "mode",
    key: "mode",
  },
  {
    title: "Огноо",
    dataIndex: "cDate",
    key: "cDate",
  },
  {
    title: "Бараа код",
    dataIndex: "itemCode",
    key: "itemCode",
  },
  {
    title: "Бараа нэр",
    dataIndex: "itemName",
    key: "itemName",
  },
  {
    title: "Зориулалт",
    dataIndex: "dedication",
    key: "dedication",
  },
  {
    title: "Үйлд.он",
    dataIndex: "productionYear",
    key: "productionYear",
  },
  {
    title: "Марк",
    dataIndex: "mark",
    key: "mark",
  },
  {
    title: "Нэгж",
    dataIndex: "unit",
    key: "unit",
  },
  {
    title: "Тоо",
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "Үнэ.$",
    dataIndex: "priceDollar",
    key: "priceDollar",
  },
  {
    title: "Гааль.₮",
    dataIndex: "custom",
    key: "custom",
  },

  {
    title: "Гарал.улс",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "Илгээгч улс",
    dataIndex: "sentCountry",
    key: "sentCountry",
  },
  {
    title: "Үнэ.₮",
    dataIndex: "priceTugrug",
    key: "priceTugrug",
  },
  {
    title: "Илгээгч",
    dataIndex: "sender",
    key: "sender",
  },
];

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className="col-span-6 md:flex items-center gap-2">
        <DatePicker picker="month" value={date} onChange={(e) => setDate(e)} />
      
          <Button type="primary" onClick={handleExport}>
            Export
          </Button>
     
      </div>
      <div className="border bg-white border-gray-200 rounded-lg shadow">
        <Table
          dataSource={data}
          columns={columns}
          loading={loading}
          pagination={true}
          rowKey={(record, index) => index}
        />
      </div>
    </div>
  );
};

export default CommercialFinished;
