import React, { useEffect, useState } from "react";
import { Button, Form as AntForm, Table, Tag, Modal, Upload } from "antd";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import { Link } from "react-router-dom";

function Doctor() {
  const [doctors, setDoctors] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const [form] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/doctors`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Овог Нэр",
      dataIndex: "lastName",
      key: "lastName",
      render: (text, row) => (
        <Link
          className="text-link hover:underline hover:text-link"
          to={`${row.id}`}
        >
          {text} {row.firstName}
        </Link>
      ),
    },
    {
      title: "Албан тушаал",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Зураг",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (imageUrl) => (
        <img src={ `${process.env.REACT_APP_CDN_URL}${imageUrl}`} alt="doctor" width={50} height={50} />
      ),
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Эрэмбэ",
      dataIndex: "orderIndex",
      key: "orderIndex",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleEdit(row)}>
          <div className="flex items-center gap-2">
            <EditOutlined /> Засах
          </div>
        </Button>
      ),
    },
  ];

  const handleAdd = () => {
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };

  const fields = [
    {
      label: "Овог",
      name: "lastName",
      rules: [{ required: true, message: "Овог оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Нэр",
      name: "firstName",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Албан тушаал",
      name: "position",
      rules: [{ required: true, message: "Албан тушаал оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Зураг",
      name: "image",
      type: "file",
      // rules: [{ required: true, message: "Зураг оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
      render: () => (
        <Upload
          name="image"
          listType="picture"
          maxCount={1}
          beforeUpload={() => false} // Prevents auto-upload, use form submission instead
        >
          <Button icon={<UploadOutlined />}>Лого сонгох</Button>
        </Upload>
      ),
    },
    {
      label: "Эрэмбэ",
      name: "orderIndex",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        type: "number",
        className: "w-full",
      },
    },
    {
      label: "Тайлбар",
      name: "description",
      className: "col-span-12 md:col-span-12 mb-0",
      inputProps: {
        className: "w-full",
        type: "textarea",
      },
    },
  ];

  const handleSubmit = (values) => {
    setLoading(true);
    let keys = Object.keys(values);
    const formData = new FormData();
    keys.map((item) => {
      formData.append(item, values[`${item}`] || "");
    });
    formData.set("image", values.image?.file ? values.image?.file : "");
    if (editData) {
      formData.append("id", editData.id);
      instance({
        method: "put",
        url: "/doctor",
        data: formData,
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/doctor",
        data: formData,
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };


  const handleCloseModal = () => {
    setEditData(null);
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={data}
          columns={columns}
          setCurrentPage={setCurrentPage}
          loading={loading}
          pagination={true}
          title={"Эмч нар"}
          handleAdd={handleAdd}
        />
      </div>
      <Modal
        title={editData ? "Эмчийн мэдээлэл засах" : "Шинэ эмч"}
        open={open}
        destroyOnClose
        width={800}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}

export default Doctor;
