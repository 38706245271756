import React, { useEffect, useState,useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form as AntForm, Input, Button } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { instance } from "utils/axios";

function ServiceForm() {
  const { id } = useParams();
  const [form] = AntForm.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const isEdit = id != "new";
  const editorRef = useRef(null); 
  useEffect(() => {
    if (isEdit) {
      instance({
        method: "get",
        url: `/service/${id}`,
      })
        .then((res) => {
          form.setFieldsValue(res.data);
        })
        .catch((err) => console.error(err));
    }
  }, [id, form]);

  const handleSubmit = (values) => {
    if (isEdit) {
   
      instance({
        method: "put",
        url: "/service", // API to update news
        data: {id,
        ...values},
      })
        .then(() => {
         navigate("/service");
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/service", // API to create new news
        data: values,
      })
        .then(() => {
        //   handleCloseModal();
         navigate("/service")
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    }

  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-2xl font-bold mb-6 text-center">
          {isEdit ? "Үйлчилгээ засах" : "Шинэ үйлчилгээ нэмэх"}
        </h1>
        <AntForm
          form={form}
          layout="vertical"
          onFinish={(values) => {
          const content = editorRef.current?.getContent(); // Editor-оос шууд агуулгыг авах
          const processedValues = {
            ...values,
            content,
          };
         handleSubmit(processedValues);
          }}
          className="space-y-6"
        >
          <AntForm.Item
            name="name"
            label="Нэр"
            rules={[
              { required: true, message: "Үйлчилгээний нэрийг оруулна уу!" },
            ]}
          >
            <Input placeholder="Нэр" />
          </AntForm.Item>
          <AntForm.Item
            name="content"
            label="Агуулга"
            rules={[{ required: true, message: "Агуулга оруулна уу!" }]}
          >
            <Editor
              apiKey="7rwd2v6wih2u0iztpuojlwhwjkagfj548s43yccfvpuy0vir"
              onInit={(evt, editor) => (editorRef.current = editor)}
              init={{
                height: window.innerWidth > 768 ? "80vh" : "100vh",
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help",
              }}
            />
          </AntForm.Item>
          <AntForm.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="w-full"
            >
              Хадгалах
            </Button>
          </AntForm.Item>
        </AntForm>
      </div>
    </div>
  );
}

export default ServiceForm;
