import React, { useEffect, useState, useRef } from "react";
import { Button } from "antd";
import { MainTable } from "components";
import { useNavigate } from "react-router-dom";
import { instance } from "utils/axios";
import moment from "moment";

function NewsList() {
    const editorRef = useRef(null); 
  const [data, setNewsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/news`, // API to fetch all news data
    })
      .then((res) => {
        setNewsData(res.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: "Гарчиг",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Нийтлэх огноо",
      dataIndex: "postDate",
      key: "postDate",
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, row) => (
        <Button size="small" onClick={() => navigate(`/news/edit/${row.id}`)}>
          Засах
        </Button>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={data}
          columns={columns}
          loading={loading}
          title={"Мэдээ"}
          handleAdd={() => navigate("/news/add")}
        />
      </div>
    </div>
  );
}

export default NewsList;
