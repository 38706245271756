import React, { useEffect, useState } from "react";
import { AuthContext } from "contexts";
import menu from "../../menu";
import { Menu } from "antd";
import { IoChevronBack } from "react-icons/io5";
import ls from "utils/ls";
import logo from "../../../assets/images/logo.png";
const Sidebar = ({}) => {
  const { state, action } = React.useContext(AuthContext);
  const [currentKey, setCurrentKey] = useState("");
  const [roleMenu, setRoleMenu] = useState([]);
  useEffect(() => {
    setCurrentKey(ls.get("currentMenuKey"));
    let tmp = [];

    menu.map((item) => {
      if (item?.role.includes(state.userInfo?.profile.role)) {
        tmp.push(item);
      }
    });
    setRoleMenu(tmp);
  }, []);

  const handleClickMenu = (e) => {
    setCurrentKey(e.key);
    ls.set("currentMenuKey", e.key);
  };

  const handleChangeCollapse = () => {
    action.handleSidebarCollapse(!state.sidebarCollapsed);
  };

  return (
    <div className="sidebar w-full overflow-hidden">
      <div className="flex justify-end">
        <button
          className="p-4 bg-transparent cursor-pointer"
          onClick={handleChangeCollapse}
        >
          <IoChevronBack
            size={20}
            className={`transition-all duration-500 ${
              state.sidebarCollapsed ? "rotate-180" : "rotate-0"
            }`}
          />
        </button>
      </div>
      <div className="flex justify-center">
        <img src={logo} height={state.sidebarCollapsed ? 20 : 40} alt="logo" />
      </div>
      <Menu
        defaultSelectedKeys={["menu-item-0"]}
        mode={"inline"}
        theme={"light"}
        selectedKeys={[currentKey]}
        onClick={handleClickMenu}
        className="text-base"
        key={"menu"}
        inlineCollapsed={state.sidebarCollapsed}
        items={roleMenu}
      />
    </div>
  );
};

export default Sidebar;
