import React, { useContext, useEffect, useState } from "react";
import { instance } from "utils/axios";
import { AuthContext } from "contexts";
import PieChart from "./PieChart";
import ColumnChart from "./ColumnChart";

const Dashboard = ({}) => {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/dashboard`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => setLoading(false));
  };

  return (
    <div className="px-1  md:px-5">
      <div className="grid grid-cols-24 gap-2">
        {Array.isArray(data?.sumData) && data.sumData.length > 0 ? (
          data.sumData.map((element) => (
            <div
              key={element.id} // Ensure each element has a unique key
              className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6"
            >
              <div>
                <div className="text-base text-muted">
                  {element.name ?? "Ангилаагүй"}
                </div>
                <div className="font-small text-xl">
                  {Intl.NumberFormat().format(element.cnt ?? 0)}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>No data available</div> // Optional: Fallback content when there's no data
        )}
      </div>

      <div className="grid grid-cols-12 gap-5 mt-5">
        <ColumnChart
          data={data?.monthPayData}
          className="col-span-12"
          alias="Сар"
          xField="quarter"
          yField="cnt"
          start={0}
        />
      </div>
    </div>
  );
};

export default Dashboard;
