import React, { useEffect, useState } from "react";
import { Button, Modal, Tag, Table } from "antd";
import { instance } from "utils/axios";

function JobApplicants() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/job/applicants`,
    })
      .then((res) => setData(res.data))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Ажлын байр",
      dataIndex: "positionId",
      key: "positionId",
      render(e, row) {
        return <span>{row.job_position.title}</span>;
      },
    },
    {
      title: "Овог",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Нэр",
      dataIndex: "firstName",
      key: "firstName",
    },

    {
      title: "Утасны дугаар",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "И-мэйл",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Ажиллах чиглэл",
      dataIndex: "directions",
      key: "directions",
    },
    {
      title: "Мэргэшсэн чиглэл",
      dataIndex: "specializations",
      key: "specializations",
    },

    {
      title: "Төлөв",
      dataIndex: "job_position",
      key: "status",
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleViewDetails(row)}>
          <div className="flex items-center gap-2">Дэлгэрэнгүй</div>
        </Button>
      ),
    },
  ];

  const handleViewDetails = (row) => {
    setSelectedApplicant(row);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setSelectedApplicant(null);
    setModalVisible(false);
  };

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <Table
          dataSource={data}
          columns={columns}
          loading={loading}
          pagination={{
            current: currentPage + 1,
            pageSize: 10,
            onChange: (page) => setCurrentPage(page - 1),
          }}
          rowKey="id"
        />
      </div>
      <Modal
        title="Ажил горилогчийн дэлгэрэнгүй мэдээлэл"
        visible={modalVisible}
        footer={null}
        onCancel={handleCloseModal}
        width={800}
      >
        {selectedApplicant && (
          <div>
            <p>
              <strong>Ажлын байр:</strong>{" "}
              {selectedApplicant.job_position?.title || "Мэдээлэл байхгүй"}
            </p>
            <p>
              <strong>Овог:</strong> {selectedApplicant.lastName}
            </p>
            <p>
              <strong>Нэр:</strong> {selectedApplicant.firstName}
            </p>
            <p>
              <strong>Утас:</strong> {selectedApplicant.phoneNumber}
            </p>
            <p>
              <strong>И-мэйл:</strong> {selectedApplicant.email}
            </p>
            <p>
              <strong>Хаяг:</strong> {selectedApplicant.address}
            </p>
            <p>
              <strong>Ажилласан жил:</strong>{" "}
              {selectedApplicant.workYear || "Мэдээлэл байхгүй"}
            </p>
            <p>
              <strong>Мэргэшил:</strong>{" "}
              {selectedApplicant.specializations || "Мэдээлэл байхгүй"}
            </p>
            <p>
              <strong>Чиглэл:</strong>{" "}
              {selectedApplicant.directions || "Мэдээлэл байхгүй"}
            </p>
            <p>
              <strong>Тайлбар:</strong>{" "}
              {selectedApplicant.job_position?.description ||
                "Мэдээлэл байхгүй"}
            </p>

            {/* Job Certifications */}
            <p>
              <strong>Гэрчилгээ:</strong>
            </p>
            {selectedApplicant.job_certifications?.length > 0 ? (
              <ul>
                {selectedApplicant.job_certifications.map((cert) => (
                  <li key={cert.id}>
                    <p>
                      <strong>Мэргэжлийн зэрэг:</strong>{" "}
                      {cert.professionalDegree || "Мэдээлэл байхгүй"}
                    </p>
                    <p>
                      <strong>Гэрчилгээний дугаар:</strong>{" "}
                      {cert.certificateNumber || "Мэдээлэл байхгүй"}
                    </p>
                    <p>
                      <strong>Лицензийн дугаар:</strong>{" "}
                      {cert.licenseNumber || "Мэдээлэл байхгүй"}
                    </p>
                    <p>
                      <strong>Лицензийн хугацаа:</strong>{" "}
                      {cert.licenseExpiryDate || "Мэдээлэл байхгүй"}
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Мэдээлэл байхгүй</p>
            )}

            {/* Job Education */}
            <p>
              <strong>Боловсрол:</strong>
            </p>
            {selectedApplicant.job_educations?.length > 0 ? (
              <ul>
                {selectedApplicant.job_educations.map((edu) => (
                  <li key={edu.id}>
                    <p>
                      <strong>Сургууль:</strong>{" "}
                      {edu.schoolName || "Мэдээлэл байхгүй"}
                    </p>
                    <p>
                      <strong>Боловсролын зэрэг:</strong>{" "}
                      {edu.degree || "Мэдээлэл байхгүй"}
                    </p>
                    <p>
                      <strong>Дипломын дугаар:</strong>{" "}
                      {edu.diplomaNumber || "Мэдээлэл байхгүй"}
                    </p>
                    <p>
                      <strong>Төгссөн он:</strong>{" "}
                      {edu.graduationYear || "Мэдээлэл байхгүй"}
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Мэдээлэл байхгүй</p>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
}

export default JobApplicants;
